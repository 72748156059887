<template>
  <v-container>
    <div class="d-flex justify-space-between">
      <div>
        <v-btn icon @click="goBack"><v-icon>arrow_back</v-icon></v-btn>
        <span class="student-name">{{ studentName }}</span>
      </div>
      <div>
        <v-btn
          v-if="asses"
          color="primary"
          outlined
          class="mr-5"
          @click="asses = false"
          >{{ !$language ? "Cancel" : "বাতিল করুন" }}</v-btn
        >
        <v-btn v-else color="primary" outlined class="mr-5" @click="download">{{
          !$language ? "Download" : "ডাউনলোড"
        }}</v-btn>
        <v-btn v-if="asses" color="primary" :loading="saveProp" @click="save">{{
          !$language ? "Save" : "সেভ করুন"
        }}</v-btn>
        <v-btn v-else color="primary" @click="asses = !asses">{{
          !$language ? "Assesment" : "মূল্যায়ন"
        }}</v-btn>
      </div>
    </div>
    <v-row v-if="loading" justify="center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-row>
    <pdf-renderer
      v-else-if="docUrl"
      :url="docUrl"
      :asses="asses"
      :save="saveProp"
      :s3-key="s3Key"
      class="mt-5"
      @save-done="onSave"
    ></pdf-renderer>
    <p v-else class="text-center">...</p>
  </v-container>
</template>

<script>
import courseworkService from "#ecf/assignment/services/CourseworkService";
import PdfRenderer from "#ecf/assessment/components/PdfRenderer.vue";
export default {
  name: "AssignmentAssesment",
  components: { PdfRenderer },
  props: {
    courseId: { type: String, required: true },
    assignmentId: { type: String, required: true },
    studentId: { type: String, required: true },
    index: { type: String, required: true },
    ext: { type: String, required: true }
  },
  data() {
    return {
      loading: true,
      docUrl: "",
      asses: false,
      s3Key: "",
      saveProp: false
    };
  },
  computed: {
    studentName() {
      return this.loading
        ? "..."
        : this.$store.state.student.studentMap[this.courseId].users[
            this.studentId
          ];
    }
  },
  mounted() {
    this.fetchStudentAndSubmission();
  },
  methods: {
    async fetchStudentAndSubmission() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        await this.$store.dispatch("student/list", {
          courseId: this.courseId,
          partner_code: this.$route.params.partner_code
        });
        let docs = await courseworkService.getSubmission(
          this.courseId,
          this.assignmentId,
          this.studentId
        );
        let mainUrl = docs.url[Number(this.index)].split("?")[0];
        let slashIndex = mainUrl.indexOf("s3.amazonaws.com/");
        this.s3Key = decodeURIComponent(mainUrl.slice(slashIndex + 17));
        let url;
        if (docs.assessed_url[Number(this.index)].length > 0) {
          // file previously assessed
          url = docs.assessed_url[Number(this.index)];
        } else if (docs.converted_url[Number(this.index)].length > 0) {
          // file not assessed but converted for assessment
          url = docs.converted_url[Number(this.index)];
        } else if (this.ext == "pdf") {
          // pdf don't need conversion
          url = docs.url[Number(this.index)];
        } else {
          // old file
          this.$root.$emit("alert", [undefined, "Can't assess this file"]);
          return;
        }
        this.docUrl = url;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loading = false;
      }
    },
    download() {
      if (this.docUrl) window.open(this.docUrl);
    },
    save() {
      this.saveProp = true;
    },
    onSave() {
      this.saveProp = false;
      this.asses = false;
      this.fetchStudentAndSubmission();
    },
    goBack(){
      this.$router.push({
        name: 'assignment-details',
        params: {
          assignment_id: this.assignmentId
        }
      })
    }
  }
};
</script>

<style scoped>
.student-name {
  font-size: 20px;
  letter-spacing: 0.4px;
  color: #202020;
}
</style>
